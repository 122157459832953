import React from 'react'
import { motion } from 'framer-motion'
import { images } from '../../constants'
import { AppWrap } from '../../wrapper'

import './Header.scss'

const scaleVarients = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut'
    }
  }
}

const Header = () => {
  return (
    <div  className='app__header app__flex'>
      <motion.div
      whileInView={{ x: [-100, 0], opacity: [0, 1] }}
      transition={{ duration: 0.5 }}
      className="app__header-info"
      >
        <div className="app__header-badge">
        <div className="badge-cmp app__flex">
          <span>👋</span>
          <div style={{ marginLeft: 20 }}>
            <p className="p-text">Hello, I am</p>
            <h1 className="head-text">Gulfaraz</h1>
          </div>
        </div>

        <div className="tag-cmp app__flex">
          <h5 className="p-text">I am a Full Stack Developer with 5 years of experience working with front-end and back-end technologies. During this time, in various roles and capacities</h5>
          {/* <p className="p-text">Freelancer</p> */}
        </div>
      </div>
      </motion.div>

      <motion.div
      whileInView={{ opacity: [0, 1] }}
      transition={{ duration: 0.5, delayChildren: 0.5 }}
      className="app__header-img"
      >
        {/* <img src={images.profile} alt="profile" />
        <motion.img
             whileInView={{ scale: [0, 1] }}
             transition={{ duration: 0.5, ease: 'easeInOut' }}
             src={ images.circle }
             className="overlay_circle"
        /> */}
         <motion.div
             whileInView={{ scale: [0, 1] }}
             transition={{ duration: 0.5, ease: 'easeInOut' }}
             className="overlay_circle"
        >
             <img src={images.profile} alt="profile" style={{ height: '70%' }} />
             <div className=" app__flex" style={{ marginTop: '2rem', marginBottom: '2rem', fontSize: '40px', flexDirection: 'column', justifyContent: 'center' }}>
          <h4 >Full Stack Web Developer</h4>
        </div>
        </motion.div>
        
      </motion.div>

<motion.div
      variant={scaleVarients}
      whileInView={scaleVarients.whileInView}
      className="app__header-circles"
      >
        {[images.aws, images.docker, images.react].map((circle, index) => (
          <div className='circle-cmp app__flex' key={`circle-${index}`}>
            <img src={circle} alt='circle'/>
          </div>
        ))}
      </motion.div>
    </div>
  )
}

export default AppWrap(Header, 'home')