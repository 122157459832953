import logo from './logo.svg';
import { Header, About, Footer, Skills, Testimonial, Work } from './container';
import { Navbar } from './components/index'
import './App.scss';

function App() {
  return (
    <div className="app">
      <Navbar />
      <Header />
      <About />
      <Work />
      <Skills />
      {/* <Testimonial /> */}
      <Footer />
    </div>
  );
}

export default App;
