import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = sanityClient({
  projectId: '8kzoqd6p',
  dataset: 'production',
  apiVersion: '2022-02-01',
  useCdn: true,
  token: 'skpW8bYQk1UErQ9KPTBtLU7v1CCftI5qmlU8pqqw706sx2ZMT2lCr7g1SyNtU6uSTFhwsSefXE6Fsje42pNTwebjp5oiQdSNDe4YZwtX35NWqkYVKFK6SzFryEGCM7VIrWBU1p4KjtgMCWpKMrQaqGXPRGukfwPBYDG4FUHR3cNZvhppVrGr',
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);