import React from 'react'
import { BsGithub, BsLinkedin, BsFileEarmarkPdfFill } from 'react-icons/bs'

const SocialMedia = () => {
  return (
    <div className='app__social'>
        <div>
          <a href="https://www.linkedin.com/in/gulfaraz-hassan-8b16331bb/" target="_blank"><BsLinkedin /></a>
        </div>
        <div>
        <a href="https://github.com/GulfarazHassan/" target="_blank"><BsGithub /></a>
        </div>
        <div>
          <a href="https://drive.google.com/file/d/1BE_-Mg9A0sU6yh_yiyUB3-XRdYJOH1tc/view?usp=sharing/" target="_blank"><BsFileEarmarkPdfFill /></a>
        </div>
    </div>
  )
}

export default SocialMedia