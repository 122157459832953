import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import { AppWrap, MotionWrap } from '../../wrapper'
import { urlFor, client } from '../../client'
import "react-tooltip/dist/react-tooltip.css";
import './Skills.scss'

const Skills = () => {
  const [skills, setSkills] = useState([])
  const [experiences, setExperiences] = useState([])

  useEffect(() => {
    const queryExperiences = '*[_type == "experiences"]'
    const querySkills = '*[_type == "skills"]'

    client.fetch(queryExperiences)
    .then(data => {
      setExperiences(data)
    })

    client.fetch(querySkills)
    .then(data => {
      setSkills(data)
    })
  }, [])
  
  return (
    <>
    <h2 className='head-text'>Skills & Experience</h2>

    <div className='app__skills-container'>
      <motion.div className='app__skills-list'>
        {skills.map(skill => (
          <motion.div
          whileInView={{ opacity: [0, 1] }}
          transition={{ duration: 0.5 }}
          className="app__skills-item app__flex"
          key={skill.name}
          >
            <div className='app__flex' style={{ backgroundColor: skill.bgColor }}>
              <img src={urlFor(skill.icon)} alt={skill.name} />
            </div>
            <p className='p-text' style={{marginTop: '10px'}}>{skill.name}</p>
          </motion.div>
        ))}
      </motion.div>

      <motion.div className='app__skills-exp'>
        { experiences?.map((experiences) => (
        <motion.div
         className='app__skills-exp-item'
         key={experiences.year}
        >
          <div className='app__skills-exp-year'>
            <p className='bold-text'>{experiences.year}</p>
          </div>
          <motion.div className='app__skills-exp-works'>
            {experiences.works.map((work) => (
        
               <motion.div
               whileInView={{ opacity: [0, 1] }}
               transition={{ duration: 0.5 }}
               className="app__skills-exp-work"
               data-tip
               data-for={work.company}
               id={work.company}
               key={work._key}
               >
                 <h4 className='bold-text'>{work.name}</h4>
                 <p className='p-text'>{work.company}</p>
               </motion.div>
              
            ))}
          </motion.div>
        </motion.div>
        ))}
        <div>
        <a href='https://drive.google.com/file/d/1BE_-Mg9A0sU6yh_yiyUB3-XRdYJOH1tc/view?usp=sharing' className={`app__skill-resume app__flex p-text item-active`} target="_blank">Resume</a>
        </div>
      </motion.div>
    </div>
    </>
  )
}

export default AppWrap(
  MotionWrap(Skills, 'app__skills'), 
  'skills',
  "app__whitebg"
  )